require('./submit-readings');
require('./day-prices');

document.addEventListener('DOMContentLoaded', function () {

    var hash = window.location.hash.substring(1);

    if (hash)
    {
        $('#' + hash).addClass('hash-selected');
    }

    // get the estimated costs when table tab is clicked
    $('.costs input#elec_table, .costs input#gas_table').click(function (event) {
        // advance data already loaded earlier?
        if (advance_calculated)
        {
            return;
        }

        // for now, we don't want to load the future prices when clicking the tab
        return;

        event.stopPropagation();
        var params = new URLSearchParams(window.location.search);
        var target = event.target;

        if (((target.id === 'elec_table') || (target.id === 'gas_table')) && (params.get('future') !== '1'))
        {
            hash = ((target.id === 'elec_table') ? 'elec_anchor' : 'gas_anchor');

            params.set('future', '1');
            params.set('future_analysis', '1');

            window.location.href = location.protocol + '//' + location.host + location.pathname +
                '?' + params.toString() + (hash ? '#' + hash : '');
        }
    });

    // open the same tab on the other product
    $('#usage .tabset input[type=radio], #costs .tabset input[type=radio]').click(function (event) {
        var target = event.target;
        var id = target.id;

        // prevent click loop
        if (event.hasOwnProperty('originalEvent'))
        {
            if (id.includes('elec'))
            {
                $('#' + id.replace('elec', 'gas')).click();
            }
            else if (id.includes('gas'))
            {
                $('#' + id.replace('gas', 'elec')).click();
            }
        }
    });

    // open the day table when goto button was clicked
    $('#goto_electricity_daily_table, #goto_gas_daily_table').click(function (event) {
        var target = event.target;

        if (target.id === 'goto_gas_daily_table')
        {
            $('#gas_day_table').click();
        }
        else if (target.id === 'goto_electricity_daily_table')
        {
            $('#elec_day_table').click();
        }
    });
});
